<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-lesson-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-form>
      <el-form-item label="Новое название урока" label-position="left">
        <el-input
          v-model="lessonName"
          placeholder="Введите новое название урока"
        />
      </el-form-item>
    </el-form>
    <footer class="up-lesson-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отменить</el-button>
      <el-button
        type="success"
        @click="saveHandler"
        :disabled="buttonSaveDisabled"
      >
        Сохранить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
import Str from "@/helpers/Str";

export default {
  name: "EditLessonModal",
  data: () => ({
    lessonName: "",
  }),
  computed: {
    buttonSaveDisabled() {
      return Str.empty(this.lessonName);
    },
  },
  methods: {
    saveHandler() {
      this.$emit("save", this.lessonName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/new/lesson-modal.scss";
</style>