<template>
  <div class="up-course">
    <h1 class="up-main-title">Создание нового курса</h1>
    <InfoForm v-model="form" ref="info-form" />
    <AppBlockAsTable v-if="lessons.length > 0" class="up-course__lessons">
      <template slot="header">
        <p class="up-course__lessons-type">Этап</p>
        <p class="up-course__lessons-name">Название</p>
      </template>
      <div v-for="(l, i) in lessons" :key="i" class="up-table__item">
        <p class="up-course__lessons-type">
          <span class="is-pk-hidden">Этап:</span>{{ i + 1 }}.
          {{ l.type === "lecture" ? "Лекция" : "Тест" }}
        </p>
        <p class="up-course__lessons-name">
          <span class="is-pk-hidden">Название:</span>{{ l.name }}
        </p>
        <p class="up-course__lessons-edit">
          <i
            class="el-icon-edit-outline"
            role="button"
            @click="openEditModal(i)"
          />
        </p>
      </div>
    </AppBlockAsTable>
    <el-alert type="warning" show-icon :closable="false" title="Внимание!">
      Для добавления дополнительной информации по лекции или тесту необходимо
      сначала сохранить курс, а потом перейти к заполнению!
    </el-alert>
    <footer class="up-course__footer">
      <el-button type="primary" @click="openModal('lecture')">
        Добавить лекцию
      </el-button>
      <el-button type="primary" @click="openModal('test')">
        Добавить тест
      </el-button>
    </footer>
    <el-button class="up-course__btn-save" type="success" @click="createCourse">
      Сохранить
    </el-button>
    <AddLessonModal
      :visible.sync="lessonModalVisible"
      :title="lessonTypeTranslated"
      @close="lessonModalVisible = false"
      @save="saveHandler"
    />
    <EditLessonModal
      :visible.sync="editLessonModalVisible"
      title="Редактирование урока"
      @close="editLessonModalVisible = false"
      @save="editLessonHandler"
      ref="edit-lesson-modal"
    />
  </div>
</template>

<script>
import editNewCourseMixin from "@/mixins/methodist/edit-new-course.mixin";

import AddLessonModal from "@/components/methodist/courses/new/AddLessonModal";
import EditLessonModal from "@/components/methodist/courses/new/EditLessonModal";

export default {
  name: "AddNewCourse",
  mixins: [editNewCourseMixin],
  components: {
    AddLessonModal,
    EditLessonModal,
  },
  data: () => ({
    lessonType: "",
    lessonModalVisible: false,
    editLessonModalVisible: false,
    editableLessonIdx: null,
  }),
  computed: {
    lessonTypeTranslated() {
      return this.lessonType === "lecture"
        ? "Добавление лекции"
        : "Добавление теста";
    },
  },
  methods: {
    openModal(type) {
      this.lessonType = type;
      this.lessonModalVisible = true;
    },
    saveHandler(name) {
      this.lessons.push({
        type: this.lessonType,
        name,
      });

      this.lessonModalVisible = false;
    },
    openEditModal(idx) {
      this.editableLessonIdx = idx;
      this.editLessonModalVisible = true;
      this.$refs["edit-lesson-modal"].lessonName = this.lessons[idx].name;
    },
    editLessonHandler(newName) {
      this.lessons[this.editableLessonIdx].name = newName;
      this.editLessonModalVisible = false;
    },
    async createCourse() {
      if (!(await this.$refs["info-form"].isValid())) return;

      try {
        const { data: res } = await this.$axios.post("/constructor/create", {
          ...this.form,
          lessons: this.lessons,
        });

        this.$message({
          message: "Поздравляем! Курс успешно создан!",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${res.id}`);
      } catch (e) {
        //
      }
    },
  },
  created() {
    this.setNavigationText("Создание нового курса");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/methodist/courses/new-show.scss";
</style>